import { AbstractHttpClient } from "@clairejs/client";
import { Register } from "@clairejs/core";

import { CreateSessionResponse } from "../../dto/http/session";
import { CreateAdminSessionBody } from "../../dto/http/user";

@Register()
export class SessionApi {
    constructor(private readonly http: AbstractHttpClient) {}

    async login(body: CreateAdminSessionBody) {
        return await this.http.post<CreateSessionResponse>("/admin-session", {
            authMethod: "PASSWORD",
            authData: [body.username, body.password],
        });
    }

    async tfaSession(code: string) {
        return await this.http.post<CreateSessionResponse>("/session/tfa", {
            code,
        });
    }
}
