import { useDispatch, useStore } from "@clairejs/react";
import { useColorModes } from "@coreui/react";
import { useEffect } from "react";

import { DataStore } from "../store";

const getAutoMode = () => {
    const now = new Date();
    return now.getHours() >= 7 && now.getHours() <= 19 ? "light" : "dark";
};

export const useColorMode = () => {
    const dispatch = useDispatch(DataStore, (s) => s.ui?.colorMode);

    const mode = useStore(DataStore, (s) => s.ui?.colorMode);

    const { colorMode, setColorMode } = useColorModes("yesfree.app");

    const setMode = (mode: string) => {
        setColorMode(mode);

        if (mode === "auto") {
            dispatch({ auto: true, mode: getAutoMode() });
        } else {
            dispatch({ auto: false, mode: mode as "dark" | "light" });
        }
    };

    useEffect(() => {
        setMode(colorMode || "dark");
    }, [colorMode]);

    useEffect(() => {
        const interval = !mode?.auto
            ? undefined
            : setInterval(() => {
                  const mode = getAutoMode();
                  dispatch({ mode });
              }, 60000);
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [mode?.auto]);

    return {
        mode,
        setMode,
    };
};
