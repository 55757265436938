import { useNavigator } from "@clairejs/react-web";
import { Button } from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .sub-title {
        margin-bottom: 13pt;
    }
`;
export default function NotFoundPage() {
    const navigator = useNavigator();
    return (
        <Wrapper>
            <h1>{"NOT FOUND"}</h1>
            <div className="sub-title">{"Trang không tồn tại"}</div>
            <Button type="link" onClick={() => navigator.back()}>
                {"Quay lại"}
            </Button>
        </Wrapper>
    );
}
