import { useDispatch, useStore } from "@clairejs/react";
import { cilAccountLogout, cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
    CAvatar,
    CDropdown,
    CDropdownDivider,
    CDropdownHeader,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
} from "@coreui/react";

import { DataStore } from "../../../store/store";

import fallbackAvatar from "../../../assets/fallback-avatar.jpg";
import { useNavigator } from "@clairejs/react-web";
import { sessionActions } from "../../../store/action/session";
import { useEffect } from "react";

const AppHeaderDropdown = () => {
    const user = useStore(DataStore, (s) => s.me?.info);
    const navigator = useNavigator();
    const dispatch = useDispatch(DataStore);

    const logout = async () => {
        await dispatch(sessionActions.logout());
        navigator.navigate("/");
    };

    useEffect(() => {
        dispatch(sessionActions.fetchUserInfoFromLocal());
    }, []);

    return (
        <CDropdown variant="nav-item">
            <CDropdownToggle className="py-0 pe-0" caret={false}>
                <CAvatar src={user?.avatar || fallbackAvatar} size="md" />
            </CDropdownToggle>

            <CDropdownMenu className="pt-0">
                <CDropdownHeader className="bg-body-secondary fw-semibold mb-2">Account</CDropdownHeader>
                <CDropdownItem href="#">
                    <CIcon icon={cilUser} className="me-2" />
                    Update profile
                </CDropdownItem>

                <CDropdownHeader className="bg-body-secondary fw-semibold my-2">Settings</CDropdownHeader>
                <CDropdownItem href="#">
                    <CIcon icon={cilUser} className="me-2" />
                    Link social accounts
                </CDropdownItem>

                <CDropdownItem href="#">
                    <CIcon icon={cilUser} className="me-2" />
                    Setup 2FA
                </CDropdownItem>

                <CDropdownDivider />
                <CDropdownItem href="#" onClick={logout}>
                    <CIcon icon={cilAccountLogout} className="me-2" />
                    Log out
                </CDropdownItem>
            </CDropdownMenu>
        </CDropdown>
    );
};

export default AppHeaderDropdown;
