import { useDispatch } from "@clairejs/react";
import { useNavigator } from "@clairejs/react-web";
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from "@coreui/react";
import { Button, Form, Input } from "antd";
import { useState } from "react";

import { sessionActions } from "../../store/action/session";
import { DataStore } from "../../store/store";

import logo from "../../assets/logo.svg";

const Login = () => {
    const navigator = useNavigator();
    const dispatch = useDispatch(DataStore);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [progress, setProgress] = useState(false);

    const disabled = !username || !password;

    const login = async () => {
        try {
            setProgress(true);
            await dispatch(sessionActions.login(username, password));
            navigator.navigate("/home");
        } finally {
            setProgress(false);
        }
    };

    return (
        <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={8}>
                        <CCardGroup>
                            <CCard className="text-white bg-primary">
                                <CCardBody className="text-center">
                                    <div>
                                        <h3>Free Admin Portal</h3>
                                    </div>
                                    <div className="d-none d-sm-block" style={{ flex: 1 }}>
                                        <img
                                            alt="logo"
                                            src={logo}
                                            style={{
                                                objectFit: "contain",
                                                maxHeight: 233,
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        />
                                    </div>
                                </CCardBody>
                            </CCard>
                            <CCard className="p-4">
                                <CCardBody>
                                    <h1>Login</h1>
                                    <p className="text-body-secondary">Sign In to your account</p>
                                    <Form>
                                        <Form.Item>
                                            <Input
                                                disabled={progress}
                                                className="custom-input"
                                                placeholder="Username"
                                                onChange={(e) => setUsername(e.target.value)}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Input.Password
                                                disabled={progress}
                                                className="custom-input"
                                                placeholder="Password"
                                                onChange={(e) => setPassword(e.target.value)}
                                                onPressEnter={login}
                                            />
                                        </Form.Item>
                                    </Form>
                                    <CRow>
                                        <CCol xs={6} className="text-right"></CCol>
                                        <CCol xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Button
                                                onClick={login}
                                                loading={progress}
                                                disabled={disabled}
                                                className="custom-button"
                                                style={{ width: "100%", marginTop: 13 }}
                                                type="primary">
                                                Log in
                                            </Button>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
};

export default Login;
