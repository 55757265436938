import React from "react";
import { CFooter } from "@coreui/react";

const AppFooter = () => {
    return (
        <CFooter className="px-4">
            <div></div>
            <div className="ms-auto">
                <span>tamidi © 2024</span>
            </div>
        </CFooter>
    );
};

export default React.memo(AppFooter);
