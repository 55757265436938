import { Input } from "antd";
import { CSSProperties, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    .inputs {
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-input {
            margin: 8px;
            width: 55px;
            height: 55px;
            font-size: 18px;
            font-weight: bold;
        }
    }
`;

interface Props {
    className?: string;
    otpLength: number;
    onChange?: (otp: string) => void;
    style?: CSSProperties;
}

export default function OtpInput({ otpLength, onChange, className, style }: Props) {
    const [inputValue, setInputValue] = useState<string[]>([]);

    const setValue = (value: string, index: number) => {
        const newOtp = [...inputValue];
        newOtp[index] = value;
        setInputValue(newOtp);
        const finalResult = newOtp.filter((c) => !!c);
        if (onChange) {
            if (finalResult.length === otpLength) {
                onChange(finalResult.join(""));
            } else {
                onChange("");
            }
        }
    };

    return (
        <Wrapper className={className || ""} style={style}>
            <div className="inputs">
                {[...Array(otpLength).keys()].map((index) => (
                    <Input
                        onPaste={(e) => {
                            const otp = e.clipboardData.getData("text");
                            setInputValue(otp.split(""));
                            onChange?.(otp);
                        }}
                        autoComplete="off"
                        style={{ textAlign: "center", fontWeight: "bold" }}
                        id={`otp-${index}`}
                        maxLength={1}
                        key={index}
                        value={inputValue[index]}
                        onChange={(e) => {
                            const value = e.target.value.trim();
                            setValue(value, index);
                            if (value) {
                                //-- jump to next input
                                if (index < otpLength - 1) {
                                    const element = document.getElementById(`otp-${index + 1}`);
                                    element?.focus();
                                }
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Backspace" && !inputValue[index]) {
                                //-- go back to previous input
                                if (index > 0) {
                                    const element = document.getElementById(`otp-${index - 1}`);
                                    element?.focus();
                                }
                            } else if (!!inputValue[index] && e.key.length === 1 && e.key.match(/[a-zA-Z0-9]/)) {
                                const value = e.key;
                                setValue(value, index);
                                if (value) {
                                    //-- jump to next input
                                    if (index < otpLength - 1) {
                                        const element = document.getElementById(`otp-${index + 1}`);
                                        element?.focus();
                                    }
                                }
                            }
                        }}
                    />
                ))}
            </div>
        </Wrapper>
    );
}
