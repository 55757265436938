import CIcon from "@coreui/icons-react";
import {
    cilBank,
    cilBullhorn,
    cilCog,
    cilFastfood,
    cilGlobeAlt,
    cilMoney,
    cilPeople,
    cilSearch,
    cilSettings,
    cilSpeedometer,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

export interface MenuItem {
    component?: any;
    name: any;
    icon?: any;
    to?: string;
    badge?: { text: string; color: string };
    indent?: boolean;
    items?: MenuItem[];
}

const _nav: MenuItem[] = [
    {
        component: CNavItem,
        name: "Dashboard",
        to: "/home/dashboard",
        icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    },
    {
        component: CNavTitle,
        name: "SEARCHES",
    },
    {
        component: CNavItem,
        name: "Searches",
        to: "/home/searches",
        icon: <CIcon icon={cilSearch} customClassName="nav-icon" />,
    },
    {
        component: CNavItem,
        name: "Configs",
        to: "/home/search-configs",
        icon: <CIcon icon={cilCog} customClassName="nav-icon" />,
    },
    {
        component: CNavTitle,
        name: "DATA",
    },
    {
        component: CNavGroup,
        name: "Users",
        icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
        items: [
            {
                component: CNavItem,
                name: "Users list",
                to: "/home/data/users/list",
            },
            {
                component: CNavItem,
                name: "Users map",
                to: "/home/data/users/map",
            },
        ],
    },
    {
        component: CNavItem,
        name: "Services",
        to: "/home/data/services",
        icon: <CIcon icon={cilFastfood} customClassName="nav-icon" />,
    },
    {
        component: CNavItem,
        name: "Countries",
        to: "/home/data/countries",
        icon: <CIcon icon={cilGlobeAlt} customClassName="nav-icon" />,
    },
    {
        component: CNavTitle,
        name: "PAYMENT",
    },
    {
        component: CNavItem,
        name: "Payments",
        to: "/home/payments/list",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    },
    {
        component: CNavGroup,
        name: "Accounts",
        icon: <CIcon icon={cilBank} customClassName="nav-icon" />,
        items: [
            {
                component: CNavItem,
                name: "Accounts",
                to: "/home/payments/accounts",
            },
            {
                component: CNavItem,
                name: "Currencies",
                to: "/home/payments/currencies",
            },
        ],
    },
    {
        component: CNavTitle,
        name: "SYSTEM",
    },
    {
        component: CNavItem,
        name: "Announcements",
        to: "/home/system/announces",
        icon: <CIcon icon={cilBullhorn} customClassName="nav-icon" />,
    },
    {
        component: CNavItem,
        name: "Settings",
        to: "/home/system/settings",
        icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
    },
];

export default _nav;
