import { ModelStore } from "@clairejs/react";

import { Country } from "../dto/models/country";
import { Currency } from "../dto/models/currency";
import { GeoRegion } from "../dto/models/geo-region";
import { Payment } from "../dto/models/payment";
import { NetworkInfo, PaymentAccount } from "../dto/models/payment-account";
import { Search } from "../dto/models/search";
import { Service } from "../dto/models/service";
import { ServiceProfile } from "../dto/models/service-profile";
import { SystemAnnounce } from "../dto/models/system-announce";
import { User } from "../dto/models/user";
import { UserVoice } from "../dto/models/user-voice";

import { UserInfo } from "../dto/http/user";

export class DataStore {
    ui?: {
        colorMode?: {
            mode?: "dark" | "light";
            auto?: boolean;
        };
        sidebar?: {
            unfoldable?: boolean;
            visible?: boolean;
        };
    };
    me?: {
        id?: string;
        info?: UserInfo;
    };
    users?: ModelStore<User>;
    payments?: ModelStore<Payment>;
    search?: ModelStore<Search>;
    data?: {
        geoRegions?: ModelStore<GeoRegion>;
        countries?: ModelStore<Country>;
        services?: ModelStore<Service>;
        accounts?: ModelStore<PaymentAccount>;
        currencies?: ModelStore<Currency>;
        networkMap?: Record<number, NetworkInfo>;
    };
    services?: {
        profiles?: ModelStore<ServiceProfile>;
    };
    system?: {
        announces?: ModelStore<SystemAnnounce>;
        uservoices?: ModelStore<UserVoice>;
    };
}
