import { RouterConfig } from "@clairejs/client";
import { importDelay } from "@clairejs/react";
import { lazy } from "react";

import App from "../pages/app";
import AdminHome from "../pages/home";
import LoginPage from "../pages/login";
import NotFoundPage from "../pages/notfound/not-found";

import { AuthGuard } from "./auth-guard";

const Dashboard = lazy(importDelay(import("../pages/home/dashboard")));
const SystemSettings = lazy(importDelay(import("../pages/home/system/settings/settings")));
const SystemPriceSettings = lazy(importDelay(import("../pages/home/system/settings/price-settings")));
const ManageSystemAnnounce = lazy(importDelay(import("../pages/home/system/announce")));

const UserDetail = lazy(importDelay(import("../pages/home/users/detail")));
const UserTransactions = lazy(importDelay(import("../pages/home/users/transactions")));
const UserList = lazy(importDelay(import("../pages/home/users/users")));
const ProfileList = lazy(importDelay(import("../pages/home/users/profile-list")));
const UsersMap = lazy(importDelay(import("../pages/home/users/users-map")));
const ProfilesOfService = lazy(importDelay(import("../pages/home/manage-data/profiles-of-service")));

const SearchList = lazy(importDelay(import("../pages/home/manage-data/search-list")));
const ListServices = lazy(importDelay(import("../pages/home/manage-data/services")));
const CountryList = lazy(importDelay(import("../pages/home/manage-data/countries")));
const SearchDetail = lazy(importDelay(import("../pages/home/manage-data/search-detail")));

const ManageTransactions = lazy(importDelay(import("../pages/home/payment/transactions")));
const CurrencyList = lazy(importDelay(import("../pages/home/payment/currencies")));
const PaymentAccountList = lazy(importDelay(import("../pages/home/payment/payment-account-list")));

const routes: RouterConfig = {
    path: "",
    component: { view: App },
    redirect: "home",
    children: [
        { path: "login", component: { view: LoginPage } },
        {
            middleware: [AuthGuard],
            path: "home",
            redirect: "dashboard",
            component: { view: AdminHome },
            children: [
                {
                    path: "dashboard",
                    component: { view: Dashboard },
                },
                { path: "searches", component: { view: SearchList } },
                {
                    path: "searches/:id",
                    component: { view: SearchDetail },
                },
                {
                    path: "searches/config",
                    component: { view: SystemPriceSettings },
                },
                { path: "search-configs", component: { view: SystemPriceSettings } },
                {
                    path: "yesfree/services/:id",
                    component: { view: ProfilesOfService },
                },
                {
                    path: "yesfree/users/:id",
                    component: { view: UserDetail },
                    redirect: "transactions",
                    children: [
                        { path: "transactions", component: { view: UserTransactions } },
                        { path: "profiles", component: { view: ProfileList } },
                    ],
                },
                {
                    path: "data",
                    children: [
                        {
                            path: "users",
                            children: [
                                {
                                    path: "list",
                                    component: { view: UserList },
                                },
                                { path: "map", component: { view: UsersMap } },
                            ],
                        },
                        { path: "services", component: { view: ListServices } },
                        { path: "services/:id", component: { view: ProfilesOfService } },
                        { path: "countries", component: { view: CountryList } },
                    ],
                },
                {
                    path: "payments",
                    children: [
                        { path: "list", component: { view: ManageTransactions } },
                        { path: "accounts", component: { view: PaymentAccountList } },
                        { path: "currencies", component: { view: CurrencyList } },
                    ],
                },
                {
                    path: "system",
                    children: [
                        { path: "announces", component: { view: ManageSystemAnnounce } },
                        { path: "settings", component: { view: SystemSettings } },
                    ],
                },
            ],
        },
        { path: "*", component: { view: NotFoundPage } },
    ],
};

export default routes;
