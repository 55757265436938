import { AbstractHttpClient } from "@clairejs/client";
import { getServiceProvider } from "@clairejs/core";
import { useDispatch } from "@clairejs/react";
import { Outlet, useNavigator } from "@clairejs/react-web";
import { APIProvider } from "@vis.gl/react-google-maps";
import { ConfigProvider, Modal, theme } from "antd";
import { useEffect } from "react";

import { ApiClient } from "../utils/api-client";

import { sessionActions } from "../store/action/session";
import { DataStore } from "../store/store";

import { useColorMode } from "../store/hooks/ui";

import OtpInput from "../components/otp-input";

const mapApiKey = "AIzaSyCjCTxRd6gqrEiodwgyQ8kv8IEEUYNbrxI";

export default function App() {
    const navigator = useNavigator();
    const { mode } = useColorMode();

    const dispatch = useDispatch(DataStore);

    useEffect(() => {
        //-- set up session expired handle
        const sp = getServiceProvider();
        const httpClient = sp.getInjector().resolve(AbstractHttpClient) as ApiClient;

        httpClient.sessionExpiredCallback = () => {
            Modal.confirm({
                title: "Phiên làm việc hết hạn",
                content: "Bạn vui lòng đăng nhập lại",
                onOk: async () => {
                    await dispatch(sessionActions.logout());
                    navigator.navigate("/");
                },
            });
        };

        httpClient.tfaRequiredCallback = () => {
            let code = "";

            return new Promise<void>((resolve) => {
                Modal.confirm({
                    width: 600,
                    title: "Yêu cầu TFA",
                    content: (
                        <div>
                            <div style={{ textAlign: "center", marginBottom: 5 }}>
                                {"Vui lòng nhập TFA để xác thực"}
                            </div>
                            <OtpInput
                                style={{ margin: 21 }}
                                otpLength={6}
                                onChange={(otp) => {
                                    code = otp;
                                }}
                            />
                        </div>
                    ),
                    onCancel: () => resolve(),
                    onOk: async () => {
                        //-- upgrade tfa token using otp
                        try {
                            await dispatch(sessionActions.tfaUpgrade(code));
                        } finally {
                            resolve();
                        }
                    },
                });
            });
        };
    }, [dispatch, navigator]);

    return (
        <ConfigProvider theme={{ algorithm: mode?.mode === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm }}>
            <APIProvider apiKey={mapApiKey}>
                <Outlet />
            </APIProvider>
        </ConfigProvider>
    );
}
