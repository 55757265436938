import { Register } from "@clairejs/core";
import { AbstractViewMiddleware, UrlInfo, AbstractTokenManager } from "@clairejs/client";

@Register()
export class AuthGuard extends AbstractViewMiddleware {
    constructor(readonly tokenManger: AbstractTokenManager) {
        super();
    }

    async intercept(from: UrlInfo | undefined, to: UrlInfo): Promise<Partial<UrlInfo> | undefined> {
        const accessToken = await this.tokenManger.getAccessToken();
        if (accessToken) {
            return undefined;
        }

        return { path: "/login" };
    }
}
