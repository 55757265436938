import React from "react";
import { CContainer } from "@coreui/react";

interface Props {
    children?: any;
}
const AppContent = ({ children }: Props) => {
    return (
        <CContainer className="px-4" lg style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            {children}
        </CContainer>
    );
};

export default React.memo(AppContent);
