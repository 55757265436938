import { RouterConfig } from "@clairejs/client";
import { useNavigator } from "@clairejs/react-web";
import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react";
import React from "react";

import navigation from "../home/nav";

const AppBreadcrumb = () => {
    const nav = useNavigator();

    const currentLocation = nav.getPath();

    const getRouteName = (pathname: string) => {
        const currentRoute = navigation.find((route) => route.to === pathname);
        return currentRoute ? currentRoute.name : false;
    };

    const getBreadcrumbs = (location: string) => {
        const breadcrumbs: { pathname: string; name: string; active: boolean }[] = [];
        location.split("/").reduce((prev, curr, index, array) => {
            const currentPathname = `${prev}/${curr}`;
            const routeName = getRouteName(currentPathname);

            if (routeName) {
                breadcrumbs.push({
                    pathname: currentPathname,
                    name: routeName,
                    active: index + 1 === array.length ? true : false,
                });
            }

            return currentPathname;
        });
        return breadcrumbs;
    };

    const breadcrumbs = getBreadcrumbs(currentLocation);

    return (
        <CBreadcrumb className="my-0">
            <CBreadcrumbItem href="/">Home</CBreadcrumbItem>
            {breadcrumbs.map((breadcrumb, index) => {
                return (
                    <CBreadcrumbItem
                        {...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })}
                        key={index}>
                        {breadcrumb.name}
                    </CBreadcrumbItem>
                );
            })}
        </CBreadcrumb>
    );
};

export default React.memo(AppBreadcrumb);
