import { Register } from "@clairejs/core";
import { AbstractErrorHandler } from "@clairejs/client";
import { message } from "antd";

@Register()
export class ErrorHandler extends AbstractErrorHandler {
    handle(err: any, component?: any): void {
        const error = err.reason?.response ? err.reason.response.data : err.reason || err;
        message.error(`${error.name}${error.message ? `: ${error.message}` : ""}`);
    }
}
