import {
    AbstractHttpClient,
    AbstractStorage,
    AbstractTokenManager,
    DefaultTokenManager,
    Translator,
} from "@clairejs/client";
import {
    AbstractLogger,
    AbstractProvider,
    Configuration,
    ConsoleLogMedium,
    DefaultLogger,
    getServiceProvider,
    Inject,
    LogLevel,
    Provider,
    setSystemLocale,
} from "@clairejs/core";
import { ClaireApp } from "@clairejs/react";
import { LocalStorage, ReactWebRoutes } from "@clairejs/react-web";
import { createRoot } from "react-dom/client";

import type { Config } from "./config/config";

import "./utils/error-handler";

import "./index.scss";
import routes from "./routers";
import { ApiClient } from "./utils/api-client";
import { DataStore } from "./store/store";

const env = process.env.REACT_APP_ENV || "local";

@Provider(Configuration)
export class ConfigProvider extends AbstractProvider<Configuration> {
    provide() {
        return Object.assign(new Configuration(), require(`./config/${env}`).default);
    }
}

@Provider(AbstractLogger)
export class LoggerProvider extends AbstractProvider<AbstractLogger> {
    provide(): AbstractLogger {
        return new DefaultLogger(LogLevel.DEBUG, [new ConsoleLogMedium()]);
    }
}

@Provider(AbstractHttpClient)
export class HttpClientProvider extends AbstractProvider<AbstractHttpClient> {
    constructor(
        @Inject(Configuration) readonly config: Config,
        readonly logger: AbstractLogger,
        readonly tokenManager: AbstractTokenManager,
    ) {
        super();
    }

    provide(): AbstractHttpClient {
        return new ApiClient(this.config.API_SERVER_URL, this.tokenManager, this.logger);
    }
}

@Provider(AbstractTokenManager)
export class TokenManagerProvider extends AbstractProvider<AbstractTokenManager> {
    constructor(readonly storage: AbstractStorage) {
        super();
    }

    provide(): AbstractTokenManager {
        return new DefaultTokenManager(this.storage);
    }
}

const bootstrap = async () => {
    const serviceProvider = getServiceProvider();
    serviceProvider.register(LocalStorage);
    serviceProvider.register(Translator);

    setSystemLocale("vi");

    //-- init translation
    const injector = serviceProvider.getInjector();
    await injector.initInstances();
};

const container = document.getElementById("root")!;

createRoot(container).render(
    <ClaireApp bootstrap={bootstrap} stores={[DataStore]}>
        <ReactWebRoutes routeConfig={routes} />
    </ClaireApp>,
);
